const brandColors = {
   // Primary Branding Colors
   blue: '#02527F',
   lightBlue: '#33a1fd',
   darkBlue: '#003c69',
   gold: '#f5a623',
   darkGray: 'rgb(63, 63, 63)',
   mediumGray: 'rgb(102, 102, 102)',
   lightGray: 'rgb(204, 204, 204)',

   // Secondary Branding Colors
   green: '#208838',
   red: '#dc3545',
   white: 'rgb(255, 255, 255)',

   // Web Colors
   mediumLightGray: 'rgb(153,153,153)',
   extraLightGray: 'rgb(230, 230, 230)',
};

export { brandColors };
