// This component handles all of the top-level routes (/*)
// Child routes (/**/*) should preferably be handled by the top-level routes' components and not included here

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';

import { AuthRouter } from './AuthRouter';

// Using lazy here splits out each of these into a separate chunk (code splitting)
// this prevents the initial load time from becoming too long by reducing the size of the root chunk
// Without code splitting, the user will have to download the entire application at once rather than just the screen they want
const RegistrationScreen = lazy(() =>
   import('screens/registrationScreen/RegistrationScreen')
);
const ForgotPasswordScreen = lazy(() =>
   import('screens/account/forgotPasswordScreen/ForgotPasswordScreen')
);
const ForgotUsernameScreen = lazy(() =>
   import('screens/account/forgotUsernameScreen/ForgotUsernameScreen')
);

const RootRouter = () => (
   <Suspense fallback={<LoadingScreen />}>
      <Switch>
         <Route path="/register/" component={RegistrationScreen} />
         <Route
            path="/forgot-password"
            exact
            component={ForgotPasswordScreen}
         />
         <Route
            path="/forgot-username"
            exact
            component={ForgotUsernameScreen}
         />
         <Route component={AuthRouter} />
      </Switch>
   </Suspense>
);

export { RootRouter };
