import { useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'appRoot/auth/AuthContext';

const RenderForUserGroups = ({
   authorizedGroups,
   requireAllAuthorized,
   unauthorizedGroups,
   children,
   groups
}) => {
   const {
      userData: { groups:loggedInUserGroups },
   } = useContext(AuthContext);

   const groupsToCheck = (groups || []).length !== 0 ? groups : loggedInUserGroups;

   const authorized =
      authorizedGroups == null ||
      (requireAllAuthorized
         ? authorizedGroups.every((group) => groupsToCheck.indexOf(group) !== -1)
         : authorizedGroups.some((group) => groupsToCheck.indexOf(group) !== -1));

   const unauthorized =
      unauthorizedGroups &&
      groupsToCheck.reduce(
         (unauth, currentGroup) =>
            unauth || unauthorizedGroups.indexOf(currentGroup) !== -1,
         false
      );

   return authorized && !unauthorized ? children : null;
};

RenderForUserGroups.propTypes = {
   authorizedGroups: PropTypes.arrayOf(PropTypes.string),
   unauthorizedGroups: PropTypes.arrayOf(PropTypes.string),
   groups: PropTypes.arrayOf(PropTypes.string),
   requireAllAuthorized: PropTypes.bool,
   children: PropTypes.node.isRequired,
};

RenderForUserGroups.defaultProps = {
   authorizedGroups: null,
   unauthorizedGroups: null,
   requireAllAuthorized: false,
   groups: null
};

export { RenderForUserGroups };
