import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';

const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));
const SpUploadStatusScreen = lazy(() =>
   import('./spUploadStatusScreen/SpUploadStatusScreen')
);
const TnExceptionsScreen = lazy(() =>
   import('./tnExceptionsScreen/TnExceptionsScreen')
);
const TnHistoryScreen = lazy(() => import('./tnHistoryScreen/TnHistoryScreen'));
const TnQueryHistoryScreen = lazy(() =>
   import('./tnQueryHistoryScreen/TnQueryHistoryScreen')
);
const UpdateTnsScreen = lazy(() => import('./updateTnsScreen/UpdateTnsScreen'));

const ManageTnsRouter = () => {
   const { url } = useRouteMatch();
   return (
      <Suspense fallback={<LoadingScreen />}>
         <Switch>
            <Route
               path={`${url}/sp-upload-status`}
               component={SpUploadStatusScreen}
            />
            <Route
               path={`${url}/tn-exceptions`}
               component={TnExceptionsScreen}
            />
            <Route path={`${url}/tn-history`} component={TnHistoryScreen} />
            <Route
               path={`${url}/tn-query-history`}
               component={TnQueryHistoryScreen}
            />
            <Route path={`${url}/update-tns`} component={UpdateTnsScreen} />
            <Route component={NotFoundScreen} />
         </Switch>
      </Suspense>
   );
};

export { ManageTnsRouter };
