import React from 'react';
import { SiteBody } from '@somosgov/react-component-lib';
import PropTypes from 'prop-types';

import { SiteHeader } from './SiteHeader';
import { SiteFooter } from './SiteFooter';

const SiteLayout = ({ children }) => (
   <>
      <SiteHeader />
      <SiteBody fixedHeader>{children}</SiteBody>
      <SiteFooter copyrightDate={`${new Date().getFullYear()}`} />
   </>
);

SiteLayout.propTypes = { children: PropTypes.node.isRequired };

export { SiteLayout };
