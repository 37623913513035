import React from 'react';

const RndEmail = () => (
   <a
      href="mailto:support@reassigned.us"
      className="alert-link"
      style={{ display: 'inline-block' }}
   >
      support@reassigned.us
   </a>
);

export { RndEmail };
