const mapCognitoErrors = (message) => {
   if (
      message === 'User does not exist.' ||
      message.match(/validation error/) ||
      message === 'Incorrect username or password.'
   )
      return 'Invalid Username/Password entered. Try again or use the links below if you have forgotten your credentials.';

   return message;
};

export { mapCognitoErrors };
