import React from 'react';

const RndPhone = () => (
   <a
      href="tel:8337632366"
      className="alert-link"
      style={{ display: 'inline-block' }}
   >
      833-763-2366
   </a>
);

export { RndPhone };
