import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SignIn } from 'aws-amplify-react';

import { Formik, Form } from 'formik';
import {
   FormikBasicField,
   createBreakpoint,
} from '@somosgov/react-component-lib';
import { object, string } from 'yup';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormButtonWrapper } from 'profileManagement/FormButtonWrapper';
import { Title } from 'components/layout/Title';
import RequiredFieldIndicator from 'components/layout/RequiredFieldIndicator';
import { mapCognitoErrors } from '../../appRoot/auth/mapCognitoErrors';
import { AuthContext } from '../../appRoot/auth/AuthContext';
import { CallerLegalAgreement } from 'legal/CallerLegalAgreement';

import { LoadingOverlay } from '@somosgov/react-component-lib';
import { LoginFailed } from './LoginFailed';

const TimeoutMessage = () => {
   const { activityStatus } = useContext(AuthContext);
   return activityStatus === 'timeout' ? (
      <div className="alert alert-warning pl-3 mt-5">
         You have been logged out due to inactivity.
      </div>
   ) : null;
};

const LoginWrapper = styled.div`
   max-width: 25rem;
   margin: 0 auto;
`;

const Error = styled.div`
   ${({ theme }) => createBreakpoint('above', theme.breakpoints.sm)} {
      margin-left: -6rem;
      margin-right: -6rem;
   }
`;

const LegalMessage = styled.div`
   overflow: auto;

   ::-webkit-scrollbar-thumb {
      background: #856404;
   }
`;

const validationSchema = object({
   username: string().required().label('Username'),
   password: string().required().label('Password'),
});

const LoginForm = ({ onSubmit }) => {
   return (
      <Formik
         onSubmit={onSubmit}
         validationSchema={validationSchema}
         initialValues={{ username: '', password: '' }}
         validateOnMount
      >
         {({ isValid }) => (
            <Form>
               <RequiredFieldIndicator />
               <FormikBasicField label="Username" name="username" required />
               <FormikBasicField
                  label="Password"
                  name="password"
                  required
                  type="password"
               />

               <FormButtonWrapper>
                  <button
                     className="btn btn-primary"
                     type="submit"
                     disabled={!isValid}
                     id="login-btn"
                  >
                     Login
                  </button>
               </FormButtonWrapper>
            </Form>
         )}
      </Formik>
   );
};

const ErrorText = ({ error }) => {
   if (
      error === 'User is disabled.' ||
      error ===
         'PreAuthentication failed with error Your account is currently locked.'
   ) {
      return <LoginFailed />;
   }
   return <span>{error}</span>;
};

ErrorText.propTypes = {
   error: PropTypes.string.isRequired,
};

LoginForm.propTypes = { onSubmit: PropTypes.func.isRequired };

class Login extends SignIn {
   state = { error: null, loading: false };

   handleFormSubmit = ({ username, password }) => {
      this.setState({ error: null, loading: true });
      this.inputs = { username, password };
      super.signIn();
   };

   triggerAuthEvent = (event) => {
      if (event.type === 'error') {
         this.setState({ error: mapCognitoErrors(event.data), loading: false });
      }
      const state = this.props.authState;
      if (this.props.onAuthEvent) {
         this.props.onAuthEvent(state, event, false);
      }
   };

   render() {
      if (this.props.authState !== 'signIn') {
         return null;
      }
      return (
         <div className="container">
            <LoginWrapper className="pb-5">
               <Title title="Login" />
               <h1 className="mt-5 mb-3">Login to RND</h1>

               {!this.state.error && !this.state.loading && <TimeoutMessage />}
               {this.state.error && (
                  <Error className="alert alert-danger pl-3">
                     <ErrorText error={this.state.error} />
                  </Error>
               )}
               <LoginForm onSubmit={this.handleFormSubmit} />
               <p className="mt-5">
                  <Link to="/forgot-username">Forgot Username?</Link>
               </p>
               <p>
                  <Link to="/forgot-password">Forgot Password?</Link>
               </p>
               {this.state.loading && <LoadingOverlay />}
               <LegalMessage className="alert alert-warning pl-3 mt-5 mb-0">
                  <small>
                     <strong>
                        By Logging in to the RND you are agreeing to the
                        following:
                     </strong>{' '}
                     {CallerLegalAgreement}
                  </small>
               </LegalMessage>
            </LoginWrapper>
         </div>
      );
   }
}

export { Login };
