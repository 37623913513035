import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';
import { USER_GROUPS } from 'util/constants';
import { DisplayScreenForUserGroups } from 'components/auth/DisplayScreenForUserGroups';

const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));
const ProfileScreen = lazy(() =>
   import('screens/account/profileScreen/ProfileScreen')
);
const UsersRouter = lazy(() =>
   import('screens/account/usersScreen/UsersRouter')
);
const CompanyScreen = lazy(() =>
   import('screens/account/companyScreen/CompanyScreen')
);
const AddClientCompanyScreen = lazy(() =>
   import('screens/account/addClientCompanyScreen/AddClientCompanyScreen')
);
const ApiCredentialsScreen = lazy(() =>
   import('screens/account/apiCredentialsScreen/ApiCredentialsScreen')
);
const SftpCredentialsScreen = lazy(() =>
   import('screens/account/sftpCredentialsScreen/SftpCredentialsScreen')
);
const NotificationsScreen = lazy(() =>
   import('screens/account/notificationsScreen/NotificationsScreen')
);
const ChangePasswordScreen = lazy(() =>
   import('screens/account/changePasswordScreen/ChangePasswordScreen')
);
const CreateSubscriptionScreen = lazy(() =>
   import('screens/account/subscriptionScreen/CreateSubscriptionScreen')
);

const ManageSubscriptionScreen = lazy(() =>
   import('screens/account/subscriptionScreen/ManageSubscriptionScreen')
);

const AccountRouter = () => {  
   const { url } = useRouteMatch();

   return (
      <Suspense fallback={<LoadingScreen />}>
         <Switch>
            <Route
               path={`${url}/profile/password`}
               component={ChangePasswordScreen}
            />
            <Route path={`${url}/profile/`} component={ProfileScreen} />
            <Route path={`${url}/users/`} component={UsersRouter} />
            <Route
               path={`${url}/api-credentials/`}
               component={ApiCredentialsScreen}
            />
            <Route
               path={`${url}/sftp-credentials/`}
               component={SftpCredentialsScreen}
            />
            <Route
               exact
               path={`${url}/clientCompany/:companyId`}
               component={CompanyScreen}
            />
            <Route exact path={`${url}/company`} component={CompanyScreen} />
            <Route
               path={`${url}/company/add-client`}
               component={AddClientCompanyScreen}
            />
            <Route
               path={`${url}/notifications`}
               component={NotificationsScreen}
            />
            <DisplayScreenForUserGroups authorizedGroups={[
                  USER_GROUPS.CALLER,
                  USER_GROUPS.COMPANY_ADMIN,
               ]}
               requireAllAuthorized>
               <Route
                  path={`${url}/subscription/create`}
                  component={CreateSubscriptionScreen}
               />
               <Route
                  path={`${url}/subscription/renew`}
                  component={CreateSubscriptionScreen}
               />
               <Route
                  path={`${url}/subscription/manage`}
                  component={ManageSubscriptionScreen}
               />
            </DisplayScreenForUserGroups>
            <Route component={NotFoundScreen} />
         </Switch>
      </Suspense>
   );
};

export { AccountRouter };
