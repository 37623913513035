// Ignored because yup uses this syntax
/* eslint-disable no-template-curly-in-string */
// Ignored so we can use PropTypes.object for objects that may have variable shape
/* eslint-disable react/forbid-prop-types */

import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormikBasicField } from '@somosgov/react-component-lib';
import PropTypes from 'prop-types';
import { string, object, ref } from 'yup';
import {
   BsCheckCircle,
   BsXCircle,
   BsEyeFill,
   BsEyeSlashFill,
} from 'react-icons/bs';
import styled from 'styled-components';

import { FormButtonWrapper } from 'profileManagement/FormButtonWrapper';

import RequiredFieldIndicator from 'components/layout/RequiredFieldIndicator';

const Input = ({ form, field, ...props }) => {
   let validationClass = '';
   if (form.touched[field.name] && form.errors[field.name]) {
      validationClass = 'is-invalid';
   } else if (form.touched[field.name] && form.values[field.name] !== '') {
      validationClass = 'is-valid';
   }

   return (
      <input
         className={`form-control ${validationClass}`}
         // eslint-disable-next-line react/jsx-props-no-spreading
         {...field}
         // Disabled because these props are passed from parent and could be any input attr
         // eslint-disable-next-line react/jsx-props-no-spreading
         {...props}
      />
   );
};

Input.propTypes = {
   form: PropTypes.objectOf(PropTypes.any).isRequired,
   field: PropTypes.objectOf(PropTypes.any).isRequired,
};

const passwordRequirements = {
   minLength: (pass) => pass?.length >= 16,
   upper: (pass) => !!pass?.match(/[A-Z]/g),
   lower: (pass) => !!pass?.match(/[a-z]/g),
};

const Item = styled.li`
   svg {
      vertical-align: text-top;
      margin-right: 0.5rem;
   }
`;

const PasswordRequirementItem = ({
   description,
   matches,
   id,
   passwordEmpty,
}) => (
   <Item id={id} className={`mt-2 ${matches ? 'text-success' : 'text-danger'}`}>
      {matches ? (
         <>
            <BsCheckCircle aria-hidden="true" />
            {!passwordEmpty && (
               <span className="sr-only">Met requirement: </span>
            )}
         </>
      ) : (
         <>
            <BsXCircle aria-hidden="true" />
            {!passwordEmpty && (
               <span className="sr-only">Unmet requirement: </span>
            )}
         </>
      )}
      <span>{description}</span>
   </Item>
);

PasswordRequirementItem.propTypes = {
   description: PropTypes.string.isRequired,
   matches: PropTypes.bool.isRequired,
   id: PropTypes.string.isRequired,
   passwordEmpty: PropTypes.bool.isRequired,
};

const PasswordRequirements = ({ password }) => {
   const matchesMinLength = passwordRequirements.minLength(password);
   const matchesUpper = passwordRequirements.upper(password);
   const matchesLower = passwordRequirements.lower(password);
   const passwordEmpty = password.length === 0;
   return (
      <div>
         <strong>Password Requirements:</strong>
         <ul className="list-unstyled">
            <PasswordRequirementItem
               passwordEmpty={passwordEmpty}
               matches={matchesMinLength}
               description="At least 16 characters"
               id="characterRequirement"
            />
            <PasswordRequirementItem
               passwordEmpty={passwordEmpty}
               matches={matchesUpper}
               description="At least 1 uppercase letter"
               id="uppercaseLetterRequirement"
            />
            <PasswordRequirementItem
               passwordEmpty={passwordEmpty}
               matches={matchesLower}
               description="At least 1 lowercase letter"
               id="lowercaseLetterRequirement"
            />
         </ul>
      </div>
   );
};
PasswordRequirements.propTypes = {
   password: PropTypes.string.isRequired,
};

const ChangePasswordForm = ({
   onSubmit,
   initialErrors,
   showCurrentPassword,
   showVerificationCode,
}) => {
   const [showPassword, setShowPassword] = useState(false);

   const rawValidationSchema = {
      verificationCode: string().required().label('Verification Code'),

      currentPassword: string().required().label('Current Password'),

      newPassword: string()
         .test(
            'is-valid',
            '${path} must meet the requirements below',
            (value) => {
               return Object.values(passwordRequirements).reduce(
                  (isValid, currentValidation) =>
                     isValid && currentValidation(value),
                  true
               );
            }
         )
         .matches(
            /^[A-Za-z 0-9+=^$*.[\]{}()?"!@#%&/\\,><':;|_~`]*$/,
            '${path} may only contain alphanumeric characters, space, and the following special characters: +=^$*.[]{}()?"!@#%&/,><\':;|_~`'
         )
         .required()
         .label('New Password'),

      confirmNewPassword: string()
         .oneOf([ref('newPassword'), null], '${path} must match New Password')
         .required()
         .label('Confirm New Password'),
   };

   if (!showCurrentPassword) delete rawValidationSchema.currentPassword;

   if (!showVerificationCode) delete rawValidationSchema.verificationCode;

   const validationSchema = object(rawValidationSchema);

   return (
      <Formik
         onSubmit={(data, formik) =>
            onSubmit(validationSchema.cast(data), formik)
         }
         validationSchema={validationSchema}
         initialValues={{
            currentPassword: '',
            verificationCode: '',
            newPassword: '',
            confirmNewPassword: '',
         }}
         initialErrors={initialErrors}
         validateOnMount
      >
         {({ isValid, errors, values }) => (
            <Form>
               <RequiredFieldIndicator />
               {showCurrentPassword && (
                  <FormikBasicField
                     label="Current Password"
                     name="currentPassword"
                     type="password"
                     required
                  />
               )}
               <div className="form-group required">
                  <label htmlFor="new-password">
                     New Password
                     <span className="sr-only"> (required)</span>
                  </label>
                  <div
                     className={`input-group ${
                        errors.newPassword ? 'is-invalid' : ''
                     }`}
                  >
                     <Field
                        name="newPassword"
                        type={`${showPassword ? 'text' : 'password'}`}
                        id="new-password"
                        autoComplete="off"
                        maxLength="64"
                        required
                        component={Input}
                        aria-describedby="uppercaseLetterRequirement characterRequirement lowercaseLetterRequirement"
                     />
                     <div className="input-group-append">
                        <button
                           type="button"
                           className="btn btn-outline-secondary"
                           onClick={() => setShowPassword(!showPassword)}
                        >
                           {showPassword ? (
                              <>
                                 <BsEyeSlashFill aria-hidden="true" />
                                 <span className="sr-only">Hide Password</span>
                              </>
                           ) : (
                              <>
                                 <BsEyeFill aria-hidden="true" />
                                 <span className="sr-only">Show Password</span>
                              </>
                           )}
                        </button>
                     </div>
                  </div>
                  <ErrorMessage
                     name="newPassword"
                     component="div"
                     className="invalid-feedback"
                  />
               </div>
               <PasswordRequirements password={values.newPassword} />
               <FormikBasicField
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  type="password"
                  maxLength="64"
                  autoComplete="off"
                  required
               />
               {showVerificationCode && (
                  <FormikBasicField
                     label="Verification Code"
                     name="verificationCode"
                     required
                  />
               )}
               <FormButtonWrapper>
                  <button
                     className="btn btn-primary"
                     type="submit"
                     disabled={!isValid}
                     id="companyFormSubmitButton"
                  >
                     Change Password
                  </button>
               </FormButtonWrapper>
            </Form>
         )}
      </Formik>
   );
};

ChangePasswordForm.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   initialErrors: PropTypes.object,
   showCurrentPassword: PropTypes.bool,
   showVerificationCode: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
   initialErrors: {},
   showCurrentPassword: true,
   showVerificationCode: false,
};

export { ChangePasswordForm };
