import React from 'react';
import PropTypes from 'prop-types';
import { RndPhone } from 'components/RndPhone';
import { RndEmail } from 'components/RndEmail';

const LoginFailed = ({ errorMessage }) => (
   <span>
      {errorMessage} Please contact RND Customer Support at <RndPhone /> or{' '}
      <RndEmail /> for assistance.
   </span>
);

LoginFailed.propTypes = {
   errorMessage: PropTypes.string,
};

LoginFailed.defaultProps = {
   errorMessage: 'Login failed.',
};

export { LoginFailed };
