import { useState, useContext } from 'react';
import { API } from 'util/API';

import { GeneralErrorContext } from 'appRoot/error/GeneralErrorContext';

const useApi = () => {
   const [response, setResponse] = useState(null);
   const [status, setStatus] = useState('pending');

   const { handleErrorResponse } = useContext(GeneralErrorContext);

   const sendRequest = (config) => {
      setStatus('loading');
      setResponse(null);
      return API(config)
         .then((resp) => {
            if (resp?.errList) {
               setResponse(config.mapResponse ? config.mapResponse(resp) : resp);
               setStatus('error');
            } else {
               setResponse(config.mapResponse ? config.mapResponse(resp) : resp);
               setStatus('success');
            }
         })
         .catch((err) => {
            if (err?.response?.data?.errList) {
               setResponse(err?.response.data);
            } else {
               handleErrorResponse(err);
            }
            setStatus('error');
         });
   };

   const reset = () => {
      setResponse(null);
      setStatus('pending');
   };

   return { response, status, sendRequest, reset };
};

export { useApi };
