import { useEffect } from 'react';
import { useApi } from './useApi';

const useSubscription = () => {
   const { status: subscriptionStatus, sendRequest: sendUserProfileRequest, response:subscription } =
      useApi();

   const mapUserProfileResponse = (unmappedResponse) => {
      const { subscription: newSubscription } = unmappedResponse;

      if (newSubscription) {
         newSubscription.subscriptionIsStarted =
            new Date(newSubscription?.termStart) <= new Date();
      }

      return newSubscription;
   };

   useEffect(() => {
      sendUserProfileRequest({
         method: 'get',
         apiName: 'rnd',
         path: '/b/api/user',
         mapResponse: mapUserProfileResponse,
      });
      
      // disabled because we don't expect sendUserProfileRequest to ever change
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return { subscription, subscriptionStatus };
};

export { useSubscription };
