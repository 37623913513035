import React, { useContext } from 'react';
import { TERMS_OF_USE_URL } from 'util/constants';
import { AuthContext } from 'appRoot/auth/AuthContext';
import { Link } from 'react-router-dom';

const privacyUrl = 'https://www.reassigned.us/privacy';
const neutralityUrl = 'https://www.reassigned.us/neutrality';
const feedbackUrl = 'https://www.reassigned.us/support';

const SiteFooter = () => {
   const { authState } = useContext(AuthContext);
   return (
      <footer
         style={{ borderTop: '1px solid #ddd' }}
         className="py-3"
         id="page-footer-sub"
      >
         <div className="container">
            <div className="row footer-content">
               <div className="footer-logo col-sm-12 col-xl-4">
                  <div className="region region-footer-sub-left">
                     <div
                        id="block-rnda-sitebranding"
                        className="block block-system block-system-branding-block"
                     >
                        <a href="/" rel="home" className="site-logo">
                           <img src="/RND_Footer.png" alt="Home" />
                        </a>
                        <div className="site-name">
                           <a href="/" title="Home" rel="home">
                              RND
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer-links col-sm-12 col-md-8 col-xl-6">
                  <div className="region region-footer-sub-center">
                     <nav
                        role="navigation"
                        aria-label="Footer"
                        id="block-rnda-footer"
                        className="block block-menu navigation menu--footer"
                     >
                        <ul className="menu-footer mb-0 pl-0">
                           {authState === 'loggedIn' && (
                              <>
                                 <li className="menu-item btn btn-link p-0">
                                    <Link to="/sitemap">Site Map</Link>
                                 </li>{' '}
                              </>
                           )}
                           <li className="menu-item btn btn-link p-0">
                              <a
                                 href={privacyUrl}
                                 rel="noreferrer noopener"
                                 target="_blank"
                              >
                                 Privacy
                              </a>
                           </li>{' '}
                           <li className="menu-item btn btn-link p-0">
                              <a
                                 href={neutralityUrl}
                                 rel="noreferrer noopener"
                                 target="_blank"
                              >
                                 Neutrality
                              </a>
                           </li>{' '}
                           <li className="menu-item btn btn-link p-0">
                              <a
                                 href={feedbackUrl}
                                 rel="noreferrer noopener"
                                 target="_blank"
                              >
                                 Support
                              </a>
                           </li>
                           <li className="menu-item btn btn-link p-0">
                              <a
                                 href={TERMS_OF_USE_URL}
                                 rel="noreferrer noopener"
                                 target="_blank"
                              >
                                 Terms of Use
                              </a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
               
            </div>
         </div>
      </footer>
   );
};

export { SiteFooter };
