import React from 'react';
import PropTypes from 'prop-types';

import { RenderForUserGroups } from './RenderForUserGroups';
import { AccessDenied } from './AccessDenied';

const DisplayScreenForUserGroups = ({
   authorizedGroups,
   requireAllAuthorized,
   unauthorizedGroups,
   children,
}) => {
   const screen = RenderForUserGroups({
      authorizedGroups,
      unauthorizedGroups,
      requireAllAuthorized,
      children,
   });
   if (screen) {
      return screen;
   }
   return <AccessDenied />;
};

DisplayScreenForUserGroups.propTypes = {
   authorizedGroups: PropTypes.arrayOf(PropTypes.string),
   unauthorizedGroups: PropTypes.arrayOf(PropTypes.string),
   requireAllAuthorized: PropTypes.bool,
   children: PropTypes.node.isRequired,
};

DisplayScreenForUserGroups.defaultProps = {
   authorizedGroups: null,
   unauthorizedGroups: null,
   requireAllAuthorized: false,
};

export { DisplayScreenForUserGroups };
