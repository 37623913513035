import React from 'react';
import { ConfirmSignIn } from 'aws-amplify-react';
import { InnerWrapper } from 'profileManagement/InnerWrapper';
import { LoadingOverlay } from '@somosgov/react-component-lib';
import { MfaVerificationForm } from './MfaVerificationForm';

class MfaChallenge extends ConfirmSignIn {
   state = { error: null, loading: false };

   triggerAuthEvent = (event) => {
      if (event.type === 'error') {
         this.setState({ error: event.data, loading: false });
      }
      const state = this.props.authState;
      if (this.props.onAuthEvent) {
         this.props.onAuthEvent(state, event, false);
      }
   };

   componentDidUpdate(prevProps) {
      if (
         this.props.authState !== prevProps.authState &&
         this.props.authState === 'signIn'
      ) {
         // This is necessary to reset the component for when it is displayed again
         // after logging in, since it doesn't unmount. Should only cause one additional update
         // eslint-disable-next-line react/no-did-update-set-state
         this.setState({ error: null, loading: false });
      }
   }

   render() {
      if (this.props.authState !== 'confirmSignIn') {
         return null;
      }

      return (
         <div className="container">
            <InnerWrapper className="mb-5">
               {this.state.error && (
                  <div className="alert alert-danger pl-3 mt-5">
                     {this.state.error}
                  </div>
               )}
               <h1 className="mt-5">Multi-Factor Verification</h1>
               <p>
                  Enter the verification code from your authenticator app to
                  complete login
               </p>
               <MfaVerificationForm
                  onSubmit={({ verificationCode }) => {
                     this.setState({ error: null, loading: true });
                     this.inputs.code = verificationCode;
                     this.confirm();
                  }}
                  submitButtonText="Verify"
               />
            </InnerWrapper>
            {this.state.loading && <LoadingOverlay />}
         </div>
      );
   }
}

export { MfaChallenge };
