// This is just an example of a custome locale for yup
// we should ideally come up with some standard validation error messages
// Then they should be put into js-util-lib and used in all of our apps

// https://github.com/jquense/yup/blob/master/src/locale.js
const yupLocale = {
   mixed: {
      required: ({ path }) => `${path} is required`,
   },
};

export { yupLocale };
