import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';

import { USER_GROUPS } from 'util/constants';
import { DisplayScreenForUserGroups } from 'components/auth/DisplayScreenForUserGroups';

const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));
const TnDisconnectQueryScreen = lazy(() =>
   import('screens/query/tnDisconnectQueryScreen/TnDisconnectQueryScreen')
);
const TnUploadQueryScreen = lazy(() =>
   import('screens/query/tnQueryUploadScreen/TnQueryUploadScreen')
);
const SubscriptionStatusScreen = lazy(() =>
   import('screens/query/subscriptionStatusScreen/SubscriptionStatusScreen')
);
const QuerySummaryReportScreen = lazy(() =>
   import('screens/query/querySummaryReportScreen/QuerySummaryReportScreen')
);
const QueryResultsScreen = lazy(() =>
   import('components/downloadsScreen/DownloadsScreen')
);

const queryReports = ['Query Summary Report', 'TN Query Upload'];

const QueryRouter = () => {
   const { url } = useRouteMatch();
   return (
      <DisplayScreenForUserGroups
         authorizedGroups={[
            USER_GROUPS.ADMIN,
            USER_GROUPS.CALLER,
            USER_GROUPS.FCC,
         ]}
      >
         <Suspense fallback={<LoadingScreen />}>
            <Switch>
               <Route
                  path={`${url}/tn-query-upload`}
                  component={TnUploadQueryScreen}
               />
               <Route
                  path={`${url}/tn-disconnect-query`}
                  component={TnDisconnectQueryScreen}
               />
               <Route
                  path={`${url}/query-results`}
                  render={() => (
                     <QueryResultsScreen
                        types={queryReports}
                        excludedColumns={['reportName']}
                        title="Downloads"
                        subtitle="Download the result files for your records. The result files will only be available for 45 days from the Create Date. Please note all date times are in Eastern time."
                     />
                  )}
               />
               <Route
                  path={`${url}/query-summary-report`}
                  component={QuerySummaryReportScreen}
               />
               <Route
                  path={`${url}/subscription-status`}
                  component={SubscriptionStatusScreen}
               />
               <Route component={NotFoundScreen} />
            </Switch>
         </Suspense>
      </DisplayScreenForUserGroups>
   );
};

export { QueryRouter };
