import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Title = (props) => {
   const { title, overrideDefault } = props;

   return (
      <Helmet>
         <title>
            {title}
            {overrideDefault ? '' : ' | RND'}
         </title>
      </Helmet>
   );
};

Title.propTypes = {
   title: PropTypes.string,
   overrideDefault: PropTypes.bool,
};

Title.defaultProps = {
   overrideDefault: false,
   title: '',
};

export { Title };