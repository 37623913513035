import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import { ChangePasswordForm } from 'screens/account/changePasswordScreen/ChangePasswordForm';
import { InnerWrapper } from 'profileManagement/InnerWrapper';
import { Auth } from 'aws-amplify';

function objectWithProperties(obj, keys) {
   const target = {};
   // Ignored because this is coppied from aws-amplify source
   /* eslint-disable no-continue */
   // eslint-disable-next-line no-restricted-syntax
   for (const key in obj) {
      if (keys.indexOf(key) === -1) {
         continue;
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
         continue;
      }
      target[key] = obj[key];
   }
   return target;
}

class ChangeTemporaryPasswordScreen extends RequireNewPassword {
   change() {
      const user = this.props.authData;
      const { password } = this.inputs;
      const { requiredAttributes } = user.challengeParam;
      const attrs = objectWithProperties(this.inputs, requiredAttributes);

      if (!Auth || typeof Auth.completeNewPassword !== 'function') {
         throw new Error(
            'No Auth module found, please ensure @aws-amplify/auth is imported'
         );
      }
      Auth.completeNewPassword(user, password, attrs)
         .then((userData) => {
            if (
               userData.challengeName === 'SMS_MFA' ||
               userData.challengeName === 'SOFTWARE_TOKEN_MFA'
            ) {
               this.changeState('confirmSignIn', userData);
            } else if (userData.challengeName === 'MFA_SETUP') {
               this.changeState('TOTPSetup', userData);
            } else {
               this.checkContact(userData);
            }
         })
         .catch((err) => this.error(err));
   }

   render() {
      if (this.props.authState !== 'requireNewPassword') {
         return null;
      }

      return (
         <div className="container">
            <InnerWrapper>
               <h1 className="mt-5 mb-3">Change Password</h1>
               <p>Your temporary password must be changed to proceed.</p>
               <div className="mb-5">
                  <ChangePasswordForm
                     onSubmit={(values) => {
                        this.inputs = { password: values.newPassword };
                        this.change();
                     }}
                     showCurrentPassword={false}
                  />
               </div>
            </InnerWrapper>
         </div>
      );
   }
}

export { ChangeTemporaryPasswordScreen };
