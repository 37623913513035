import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { FormikBasicField } from '@somosgov/react-component-lib';
import { FormButtonWrapper } from 'profileManagement/FormButtonWrapper';

import { object, string } from 'yup';

const validationSchema = object({
   verificationCode: string().required().label('Verification Code'),
});

const MfaVerificationForm = ({ onSubmit, submitButtonText }) => {
   return (
      <Formik
         onSubmit={onSubmit}
         validationSchema={validationSchema}
         initialValues={{ verificationCode: '' }}
         validateOnMount
      >
         {({ isValid }) => (
            <Form>
               <FormikBasicField
                  label="Verification Code"
                  name="verificationCode"
                  required
               />
               <FormButtonWrapper>
                  <button
                     className="btn btn-primary"
                     type="submit"
                     disabled={!isValid}
                     id="mfa-setup-btn"
                  >
                     {submitButtonText}
                  </button>
               </FormButtonWrapper>
            </Form>
         )}
      </Formik>
   );
};

MfaVerificationForm.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   submitButtonText: PropTypes.string,
};

MfaVerificationForm.defaultProps = {
   submitButtonText: 'Submit',
};

export { MfaVerificationForm };
