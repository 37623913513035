// This is a wrapper of aws-amplify's API method that provides the ID token as authorization
// The http method and other arguments are provided as a config object, init contains the optional headers and request body
import { API as awsAPI, Auth } from 'aws-amplify';

const API = ({ method, apiName, path, init = {}, authType }) => {
   if (authType === 'none') {
      return awsAPI[method](apiName, path, {
         ...init,
         headers: {
            Authorization: null,
            ...init.headers,
         },
      });
   }
   // default auth type is idToken
   return Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      return awsAPI[method](apiName, path, {
         ...init,
         headers: {
            Authorization: token,
            ...init.headers,
         },
      });
   });
};

export { API };
