import React from 'react';

const AccessDenied = () => (
   <div className="container">
      <h1 className="mt-5">
         Access Denied: You do not have the required permissions
      </h1>
   </div>
);

export { AccessDenied };
