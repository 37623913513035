import React, { useEffect, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react';
import { LoadingOverlay } from '@somosgov/react-component-lib';
import { AuthContext } from 'appRoot/auth/AuthContext';
import { InnerWrapper } from 'profileManagement/InnerWrapper';
import styled from 'styled-components';
import { GeneralErrorContext } from 'appRoot/error/GeneralErrorContext';
import { Title } from 'components/layout/Title';
import { MfaVerificationForm } from './MfaVerificationForm';

const List = styled.ol`
   li {
      margin-bottom: 1.5em;
   }
`;

const MfaSetup = () => {
   const [code, setCode] = useState(null);
   const [user, setUser] = useState(null);
   const [loading, setLoading] = useState(true);
   const [errorMsg, setErrorMsg] = useState(null);

   const { handleMfaSetupCompletion } = useContext(AuthContext);
   const { handleErrorResponse } = useContext(GeneralErrorContext);

   useEffect(() => {
      Auth.currentAuthenticatedUser().then((userData) => {
         setUser(userData);
         Auth.setupTOTP(userData).then((secretCode) => {
            setCode(secretCode);
            setLoading(false);
         });
      });
   }, []);

   return (
      <div className="container">
         <Title title="MFA Setup" />
         <InnerWrapper className="mb-5">
            <h1 className="mt-5">Multi-Factor Verification Setup</h1>
            <p>
               Before you may access the RND application, you must set up MFA
               following the steps below:
            </p>
            <List>
               <li>
                  Install an authenticator app, such as Google Authenticator, on
                  your{' '}
                  <a
                     href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     computer
                  </a>{' '}
                  or mobile device from the{' '}
                  <a
                     href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     App Store
                  </a>{' '}
                  or{' '}
                  <a
                     href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     Google Play
                  </a>
               </li>
               <li>
                  <p>Use this authenticator app to scan the QR code below:</p>
                  <p className="text-center">
                     {user && code && (
                        <QRCode
                           value={`otpauth://totp/RND:${encodeURIComponent(
                              user.username
                           )}?secret=${code}&issuer=RND`}
                        />
                     )}
                  </p>
                  <p>Or manually enter your key:</p>
                  <p>
                     <code>
                        <strong>{code}</strong>
                     </code>
                  </p>
               </li>
               <li>
                  <p>
                     Enter the code generated by the authenticator app in the
                     field below and click &quot;Submit&quot;
                  </p>
                  {errorMsg && (
                     <div className="alert alert-danger pl-3">{errorMsg}</div>
                  )}

                  <MfaVerificationForm
                     onSubmit={({ verificationCode }) => {
                        setLoading(true);
                        setErrorMsg(null);
                        Auth.verifyTotpToken(user, verificationCode)
                           .then(() => {
                              Auth.setPreferredMFA(user, 'TOTP').then(
                                 handleMfaSetupCompletion
                              );
                           })
                           .catch((err) => {
                              if (
                                 err.code === 'InvalidParameterException' ||
                                 err.code === 'EnableSoftwareTokenMFAException'
                              ) {
                                 setErrorMsg(
                                    'The code you have entered is not valid.'
                                 );
                              } else {
                                 handleErrorResponse(err);
                              }
                              setLoading(false);
                           });
                     }}
                  />
               </li>
            </List>
         </InnerWrapper>
         {loading && <LoadingOverlay />}
      </div>
   );
};

export { MfaSetup };
