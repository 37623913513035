export const { NODE_ENV } = process.env;

export const USER_GROUPS = {
   ADMIN: 'Admin',
   AGENT: 'Agents',
   CALLER: 'Callers',
   COMPANY_ADMIN: 'CompanyAdmin',
   FCC: 'FCC',
   SPTFNA: 'SPTFNA',
};

export const COMPANY_TYPES = {
   SP: 'Service Provider',
   SPA: 'Service Provider Agent',
   TFNA: 'TFNA',
   CALLER: 'Caller',
   CA: 'Caller Agent',
   FCC: 'FCC',
   RNDA: 'Rnda',
};

export const USER_STATUS = {
   PENDING: 'pending',
   ENABLED: 'enabled',
   DISABLED: 'disabled',
   CANCELLED: 'cancelled',
};

export const COMPANY_STATUS = {
   PENDING: 'pending',
   ACTIVE: 'active',
   DISABLED: 'disabled',
   DELETE: 'delete',
};

export const TERMS_OF_USE_URL = 'https://www.reassigned.us/terms';
export const CONTACT_URL = 'https://www.reassigned.us/support';
export const SUBMIT_SCHEDULE_URL =
   'https://www.reassigned.us/support#headingFour';
export const USER_GUIDES_URL = 'https://www.reassigned.us/resources/guides';
export const TRAINING_VIDEOS_URL = 'https://www.reassigned.us/resources/videos';
