// This component causes the app UI to fade in smoothly and initial loading screen to fade out smoothly and then be hidden

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const indexHtmlLoading = document.getElementById('index-html-loading');

const fadeIndexHtmlLoading = div => {
   div.classList.add('app-loaded');
};

const hideIndexHtmlLoading = div => {
   div.classList.add('app-visible');
};

const FadeIn = styled.div`
   opacity: ${({ visible }) => (visible ? 1 : 0)};
   transition: opacity 0.5s ease;
`;

FadeIn.propTypes = {
   visible: PropTypes.bool,
};

FadeIn.defaultProps = {
   visible: false,
};

const AppFadeInWrapper = ({ children }) => {
   const [visible, setVisible] = useState(false);
   const [indexHtmlLoadingVisible, setIndexHtmlLoadingVisible] = useState(true);

   useEffect(() => {
      fadeIndexHtmlLoading(indexHtmlLoading);
      setVisible(true);
   }, []);

   const onTransitionEnd = indexHtmlLoadingVisible
      ? () => {
           hideIndexHtmlLoading(indexHtmlLoading);
           setIndexHtmlLoadingVisible(false);
        }
      : null;

   return (
      <FadeIn visible={visible} onTransitionEnd={onTransitionEnd}>
         {children}
      </FadeIn>
   );
};

AppFadeInWrapper.propTypes = {
   children: PropTypes.node.isRequired,
};

export { AppFadeInWrapper };
