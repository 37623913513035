// Based off of bootstrap:
// https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints

const breakpoints = {
   sm: '576px',
   md: '768px',
   lg: '992px',
   xl: '1200px',
};

export { breakpoints };
