// This is the entrypoint to the application.
// It executes code (like polyfills) required for the APP to work on all supported browsers
// and renders the app to the DOM

// Adds polyfills to support React on IE11 (and browsers supported by the browserslist in package.json)
import 'react-app-polyfill/ie11';
// Adds polyfills for all stable JS fuctionality in supported browsers.
// This is commented out because it causes a non-trivial size increase to the initial chunk delivered to the user
// If you see type errors in IE11 or another browser that don't occur in e.g., chrome, then try uncommenting:
// import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'appRoot/App';

// Render the app root component to the DOM
ReactDOM.render(<App />, document.getElementById('index-html-root'));
