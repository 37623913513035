// This component handles all of the top-level routes (/*)
// Child routes (/**/*) should preferably be handled by the top-level routes' components and not included here

import React, { lazy, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';
import {
   Authenticator,
   VerifyContact,
   ForgotPassword,
   TOTPSetup,
   Loading,
} from 'aws-amplify-react';

import { ChangeTemporaryPasswordScreen } from 'screens/account/changeTemporaryPasswordScreen/ChangeTemporaryPasswordScreen';
import { AdminRouter } from 'screens/admin/AdminRouter';
import { SubmitRouter } from 'screens/submit/SubmitRouter';
import { QueryRouter } from 'screens/query/QueryRouter';
import { ReportsRouter } from 'screens/reports/ReportsRouter';
import { AccountRouter } from 'screens/account/AccountRouter';
import { AuthContext } from 'appRoot/auth/AuthContext';
import { MfaSetup } from 'components/auth/MfaSetup';
import { MfaChallenge } from 'components/auth/MfaChallenge';
import { Login } from '../components/auth/Login';

// Using lazy here splits out each of these into a separate chunk (code splitting)
// this prevents the initial load time from becoming too long by reducing the size of the root chunk
// Without code splitting, the user will have to download the entire application at once rather than just the screen they want
const HomeScreen = lazy(() => import('screens/HomeScreen'));
const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));
const SiteMapScreen = lazy(() => import('screens/SiteMapScreen'));

const Router = ({ authState: amplifyAuthState }) => {
   const { authState } = useContext(AuthContext);

   if (amplifyAuthState !== 'signedIn') {
      return null;
   }

   if (authState === 'loggedIn') {
      return (
         <Suspense fallback={<LoadingScreen />}>
            <Switch>
               <Route path="/" exact component={HomeScreen} />
               <Route path="/admin" component={AdminRouter} />
               <Route path="/submit" component={SubmitRouter} />
               <Route path="/query" component={QueryRouter} />
               <Route path="/reports" component={ReportsRouter} />
               <Route path="/account" component={AccountRouter} />
               <Route path="/sitemap" exact component={SiteMapScreen} />
               <Route component={NotFoundScreen} />
            </Switch>
         </Suspense>
      );
   }
   if (authState === 'mfaSetupRequired') {
      return <MfaSetup />;
   }
   return <LoadingScreen />;
};

Router.propTypes = { authState: PropTypes.string };
Router.defaultProps = { authState: null };

const AuthRouter = () => (
   <Authenticator hideDefault>
      <Login />
      <MfaChallenge />
      <ChangeTemporaryPasswordScreen />
      <VerifyContact />
      <ForgotPassword />
      <TOTPSetup />
      <Loading />
      <Router />
   </Authenticator>
);

export { AuthRouter };
