// This module adds our custom error messages and validations to Yup

import { setLocale, addMethod, string } from 'yup';

import { yupLocale } from 'util/formValidation/yupLocale';
import {
   addTnStrictToYup,
   addStripTnToYup,
   addFormatTnToYup,
} from '@somosgov/js-util-lib';

// Custom error messages for default yup validations
setLocale(yupLocale);

addStripTnToYup();

addFormatTnToYup();

addTnStrictToYup(
   /^\d{3}-\d{3}-\d{4}$/,
   ({ path }) => `${path} must have the following format: 555-303-4000`
);

addMethod(string, 'numeric', function yupNumeric() {
   return this.matches(
      /^\d*$/,
      ({ path }) => `${path} must contain only numbers.`
   );
});

addMethod(string, 'nonNumeric', function yupNonNumeric() {
   return this.matches(
      /^[^\d]*$/,
      ({ path }) => `${path} may not contain numbers.`
   );
});

addMethod(string, 'alphanumeric', function yupAlphanumeric() {
   return this.matches(
      /^[a-z\d]*$/i,
      ({ path }) => `${path} must contain only alphanumeric characters.`
   );
});

addMethod(string, 'usPostalCode', function yupUsPostalCode() {
   return this.matches(
      /^\d{5}(-\d{4})?$/,
      ({ path }) => `${path} must be a valid US postal code.`
   );
});
