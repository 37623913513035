// This is the root component for the app. Every other component is a descendent of this component

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
   RootErrorBoundary,
   NetworkErrorScreen,
   UnexpectedErrorScreen,
} from '@somosgov/react-component-lib';
import Amplify, { API } from 'aws-amplify';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './theme/date-override.css';
// NOTE: with some modification, this can be imported in each schema but only run once to reduce the App bundle size
// It will instead increase the bundle size of (I think) the first loaded page that uses yup.
// However, if it is not imported EVERYWHERE that it is needed, a potentially obscure bug will be introduced
// Therefore, this should only be done if absolutely needed to marginally improve initial page load speed
import './yupConfig';

import amplifyConfig from 'aws-exports';
import { SiteLayout } from 'components/layout/SiteLayout';
import { AppFadeInWrapper } from './app/AppFadeInWrapper';
import { RootRouter } from './RootRouter';
import { AuthProvider } from './auth/AuthProvider';
import { GeneralErrorProvider } from './error/GeneralErrorProvider';
import { Theme } from './theme/Theme';
import GlobalStyle from './theme/globalStyles';
import { ScrollToTop } from './app/ScrollToTop';

if (process.env.REACT_APP_API_URL) {
   amplifyConfig.aws_cloud_logic_custom[0].endpoint =
      process.env.REACT_APP_API_URL;
}

Amplify.configure(amplifyConfig);
API.configure(amplifyConfig);

// TODO: consider memoizing a child of App if multiple renders is a problem
const App = () => (
   <ThemeProvider theme={Theme}>
      <>
         <GlobalStyle />
         <AppFadeInWrapper>
            <Router>
               <ScrollToTop />
               <GeneralErrorProvider>
                  <AuthProvider>
                     <SiteLayout>
                        <RootErrorBoundary
                           NetworkErrorScreen={NetworkErrorScreen}
                           UnexpectedErrorScreen={UnexpectedErrorScreen}
                        >
                           <RootRouter />
                        </RootErrorBoundary>
                     </SiteLayout>
                  </AuthProvider>
               </GeneralErrorProvider>
            </Router>
         </AppFadeInWrapper>
      </>
   </ThemeProvider>
);

export { App };
