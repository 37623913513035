import { brandColors } from './brandColors';

const semanticColors = {
   primary: brandColors.blue,
   secondary: brandColors.gold,

   siteBackground: brandColors.white,

   error: brandColors.red,
   success: brandColors.green,
};

export { semanticColors };
