import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import { GeneralErrorContext } from './GeneralErrorContext';
import { deriveErrorModalInfo } from './deriveErrorModalInfo';

const GeneralErrorProvider = ({ children }) => {
   const [error, setError] = useState(null);

   const { title, message } = deriveErrorModalInfo(error);

   const handleErrorResponse = (err) => {
      if (err.message && /network/i.test(err.message)) {
         setError('network');
      } else if (err.response) {
         setError(String(err.response.status));
      } else {
         setError('500');
      }
   };

   return (
      <GeneralErrorContext.Provider value={{ error, handleErrorResponse }}>
         {children}
         {error && (
            <Popup modal open={!!error} onClose={() => setError(null)}>
               <div className="p-3">
                  <h2>{title}</h2>
                  <p>{message}</p>
                  <p className="text-right">
                     <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => setError(null)}
                     >
                        OK
                     </button>
                  </p>
               </div>
            </Popup>
         )}
      </GeneralErrorContext.Provider>
   );
};

GeneralErrorProvider.propTypes = {
   children: PropTypes.node.isRequired,
};

export { GeneralErrorProvider };
