const deriveErrorModalInfo = (error) => {
   switch (error) {
      case '403':
         return {
            title: 'Permission Denied',
            message:
               'You do not have permission to perform the requested operation.',
         };
      case 'network':
         return {
            title: 'Network Error',
            message: 'Please check your network connection and try again.',
         };
      default:
         return {
            title: 'Unexpected Error',
            message:
               'An unexpected error occurred. Contact the Help Desk if you continue to see this error.',
         };
   }
};

export { deriveErrorModalInfo };
