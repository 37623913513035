import React from 'react';

const RequiredFieldIndicator = () => {
   return (
      <div className="mb-3">
         <div style={{ fontSize: '.75rem' }}>
            <span style={{ color: '#dc3545' }}>*</span> Required
         </div>
      </div>
   );
};

// eslint-disable-next-line import/no-default-export
export default RequiredFieldIndicator;
