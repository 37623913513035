import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Group = styled.span`
   & button {
      min-width: 120px;
   }
`;

const FormButtonWrapper = ({ children }) => (
   <p className="text-center mt-4 mb-0">
      <Group className="btn-group">{children}</Group>
   </p>
);

FormButtonWrapper.propTypes = {
   children: PropTypes.node.isRequired,
};

export { FormButtonWrapper };
