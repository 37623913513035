import { brandColors } from './brandColors';
import { semanticColors } from './semanticColors';
import { breakpoints } from './breakpoints';
import { sizes } from './sizes';

const Theme = {
   brandColors,
   semanticColors,
   breakpoints,
   sizes,
};

export { Theme };
