import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';

const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));

const DownloadsScreen = lazy(() =>
   import('components/downloadsScreen/DownloadsScreen')
);

const ReportsRouter = () => {
   const { url } = useRouteMatch();

   return (
      <Suspense fallback={<LoadingScreen />}>
         <Switch>
            <Route
               path={`${url}`}
               component={() => (
                  <DownloadsScreen
                     excludedColumns={['input_file', 'type', 'username']}
                     queryType="upload"
                     title="RNDA Reports"
                     subtitle="Download reports to review"
                     includeCreateTimestamp={false}
                  />
               )}
            />
            <Route component={NotFoundScreen} />
         </Switch>
      </Suspense>
   );
};

export { ReportsRouter };
