import React, { useState, useEffect, useContext } from 'react';
import { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import { merge, fromEvent, interval } from 'rxjs';
import { tap, map, startWith, switchMap, filter } from 'rxjs/operators';
import moment from 'moment';
import Popup from 'reactjs-popup';

import { API } from 'util/API';
import { GeneralErrorContext } from 'appRoot/error/GeneralErrorContext';
import { AuthContext } from './AuthContext';

const defaultUserData = { companyIdList: [], groups: [] };

const checkInterval = 10 * 1000;
const allowedIdleTime = 20 * 60 * 1000;
const idleWarningTime = 15 * 60 * 1000;

{
   const initialLastActive = JSON.parse(localStorage.getItem('lastActive'));
   if (
      !!initialLastActive &&
      Date.now() - initialLastActive > allowedIdleTime
   ) {
      Auth.signOut({ global: true });
   }
}

const AuthProvider = ({ children }) => {
   const [mfaStatus, setMfaStatus] = useState('loading');
   const [username, setUsername] = useState(null);
   const [userData, setUserData] = useState(defaultUserData);
   const [refresh, setRefresh] = useState(false);
   const [activityStatus, setActivityStatus] = useState('active');

   const setActivityStatusWithStorage = (status) => {
      setActivityStatus(status);
      localStorage.setItem('activityStatus', status);
   };

   const { handleErrorResponse } = useContext(GeneralErrorContext);

   const initialize = () => {
      if (username) {
         API({
            method: 'get',
            apiName: 'rnd',
            path: '/b/api/user',
         })
            .then(({ subscription, ...data }) => {
               const userInfo = {
                  ...data,
                  clientRndCompanyIds: data.clientRndCompanyIds
                     ? data.clientRndCompanyIds
                     : [],
                  companyIdList: data.allowedRndCompanyIds,
                  clientIdList: data.clientRndCompanyIds,
                  allAllowedCompanyIdList: data.clientRndCompanyIds
                     ? [
                          ...data.allowedRndCompanyIds,
                          ...data.clientRndCompanyIds,
                       ]
                     : data.allowedRndCompanyIds,
                  rndCompanyId: data.allowedRndCompanyIds[0],
                  groups: data.groups,
               };

               if (userInfo.pwdLastReset) {
                  const lastReset = moment(
                     userInfo.pwdLastReset.split('T')[0]
                  ).startOf('day');

                  const today = moment().startOf('day');

                  userInfo.daysUntilPasswordExpires =
                     180 - today.diff(lastReset, 'days');
               }

               setUserData(userInfo);
            })
            .catch((error) => {
               handleErrorResponse(error);
               Auth.signOut();
            });
      } else {
         setUserData(defaultUserData);
      }
   };

   useEffect(() => {
      Hub.listen('auth', (data) => {
         const { payload } = data;

         if (payload.event === 'signIn') {
            setUsername(payload.data.username);
         }
         if (payload.event === 'signOut') {
            setUsername(null);
         }
         if (payload.event === 'refresh') setRefresh(new Date());
      });
   }, []);

   useEffect(() => {
      window.addEventListener('storage', (e) => {
         if (
            e.key === 'amplify-authenticator-authState' &&
            e.oldValue === 'signedIn' &&
            e.newValue === 'signIn'
         ) {
            Auth.signOut();
         } else if (e.key === 'activityStatus') {
            setActivityStatus(e.newValue);
         }
      });
   }, []);

   useEffect(() => {
      Auth.currentUserInfo()
         .then((user) => {
            if (user.username == null) {
               throw new Error('NoUserInfo');
            }
            setUsername(user.username);
         })
         .catch(() => {
            Auth.signOut();
         });
   }, []);

   useEffect(() => {
      setMfaStatus('loading');
      if (!userData.userName) {
         return;
      }

      Auth.currentAuthenticatedUser().then((user) => {
         Auth.getPreferredMFA(user, {
            bypassCache: true,
         }).then((preferredMfa) => {
            if (
               preferredMfa === 'SOFTWARE_TOKEN_MFA' ||
               userData.groups.includes('Test')
            ) {
               setMfaStatus('setupComplete');
            } else if (preferredMfa === 'NOMFA') {
               setMfaStatus('setupPending');
            }
         });
      });
   }, [userData]);

   useEffect(initialize, [username, refresh]);

   // disabled because no cleanup is needed if timeoutHandler is not created
   // eslint-disable-next-line consistent-return
   useEffect(() => {
      if (username != null) {
         const timeoutHandler = merge(
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'keydown'),
            fromEvent(document, 'touchstart')
         )
            .pipe(
               filter(
                  () =>
                     Date.now() -
                        JSON.parse(localStorage.getItem('lastActive')) <
                     idleWarningTime
               ),
               startWith({}),
               tap(() => {
                  if (localStorage.getItem('activityStatus') !== 'active') {
                     setActivityStatusWithStorage('active');
                  }
               }),
               map(() => Date.now()),
               tap((time) => localStorage.setItem('lastActive', time)),
               switchMap(() => interval(checkInterval)),
               map(() => Date.now()),
               filter(
                  (currentTime) =>
                     currentTime -
                        JSON.parse(localStorage.getItem('lastActive')) >
                     idleWarningTime
               ),
               tap(() => {
                  if (localStorage.getItem('activityStatus') === 'active') {
                     setActivityStatusWithStorage('idle');
                  }
               }),
               filter(
                  (currentTime) =>
                     currentTime -
                        JSON.parse(localStorage.getItem('lastActive')) >
                     allowedIdleTime
               ),
               tap(() => {
                  if (localStorage.getItem('activityStatus') === 'idle') {
                     Auth.signOut({ global: true });
                     localStorage.clear();
                     setActivityStatusWithStorage('timeout');
                  }
               })
            )
            .subscribe();
         return () => timeoutHandler.unsubscribe();
      }
   }, [username]);

   let authState;
   if (userData.userName && mfaStatus === 'setupComplete') {
      authState = 'loggedIn';
   } else if (mfaStatus === 'setupPending') {
      authState = 'mfaSetupRequired';
   } else {
      authState = 'loggedOut';
   }

   const handleMfaSetupCompletion = () => setMfaStatus('setupComplete');

   return (
      <AuthContext.Provider
         value={{
            username,
            userData,
            authState,
            activityStatus,
            handleMfaSetupCompletion,
         }}
      >
         {children}
         {activityStatus === 'idle' && (
            <Popup modal open closeOnDocumentClick={false}>
               <div className="p-3">
                  <h2>Session Timeout</h2>
                  <p>
                     Your session is about to end due to inactivity. You will be
                     logged out automatically unless you choose to remain logged
                     in.
                  </p>
                  <p className="text-right">
                     <button
                        className="btn btn-secondary mr-3"
                        type="button"
                        onClick={() => {
                           Auth.signOut({ global: true });
                           localStorage.clear();
                           setActivityStatusWithStorage('inactive');
                        }}
                     >
                        Logout Now
                     </button>
                     <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                           localStorage.setItem('lastActive', Date.now());
                           setActivityStatusWithStorage('active');
                        }}
                     >
                        Stay Logged In
                     </button>
                  </p>
               </div>
            </Popup>
         )}
         {userData?.daysUntilPasswordExpires &&
            userData?.daysUntilPasswordExpires <= 30 &&
            window.location.href.indexOf('profile/password') === -1 && (
               <Popup modal open closeOnDocumentClick={false}>
                  {(close) => (
                     <div className="p-3">
                        <h2>Password Expiration</h2>
                        <div id="passwordExpirationMessage">
                           Your password will expire in{' '}
                           <strong>{userData.daysUntilPasswordExpires}</strong>{' '}
                           day
                           {userData.daysUntilPasswordExpires === 1 ? '' : 's'}.
                           You must change your password before it expires to
                           continue using the RNDA without interruption.
                        </div>
                        <p className="text-right">
                           <button
                              className="btn btn-secondary mr-3"
                              type="button"
                              onClick={() => {
                                 close();
                              }}
                           >
                              Remind Me Later
                           </button>
                           <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => {
                                 close();
                                 window.location.href = `${window.location.origin}/account/profile/password`;
                              }}
                           >
                              Change Password
                           </button>
                        </p>
                     </div>
                  )}
               </Popup>
            )}
      </AuthContext.Provider>
   );
};

AuthProvider.propTypes = {
   children: PropTypes.node.isRequired,
};

export { AuthProvider };
