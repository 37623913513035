import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';

import { USER_GROUPS } from 'util/constants';
import { DisplayScreenForUserGroups } from 'components/auth/DisplayScreenForUserGroups';
import { ManageTnsRouter } from './manageTns/ManageTnsRouter';

const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));
const AdminQueryUsers = lazy(() =>
   import('screens/admin/usersScreen/AdminQueryUsersScreen')
);
const AdminViewWorkItemListScreen = lazy(() =>
   import('screens/admin/workItemsScreen/AdminViewWorkItemListScreen')
);
const WorkItemScreen = lazy(() =>
   import('screens/admin/workItemScreen/WorkItemScreen')
);
const CompaniesScreen = lazy(() =>
   import('screens/admin/companiesScreen/CompaniesScreen')
);
const CompanyScreen = lazy(() =>
   import('screens/admin/companyScreen/CompanyScreen')
);
const AddClientCompanyScreen = lazy(() =>
   import('screens/admin/addClientCompanyScreen/AddClientCompanyScreen')
);
const NotificationsScreen = lazy(() =>
   import('screens/admin/notificationsScreen/NotificationsScreen')
);
const BillingScreen = lazy(() =>
   import('screens/admin/billingScreen/BillingScreen')
);
const UploadReportsScreen = lazy(() =>
   import('screens/admin/uploadReportsScreen/UploadReportsScreen')
);

const UserScreen = lazy(() => import('screens/admin/userScreen/UserScreen'));

const UserHistoryScreen = lazy(() =>
   import('screens/admin/userHistoryScreen/UserHistoryScreen')
);

const CompanyHistoryScreen = lazy(() =>
   import('screens/admin/companyHistoryScreen/CompanyHistoryScreen')
);

const ResultFilesScreen = lazy(() =>
   import('components/downloadsScreen/DownloadsScreen')
);

const CompanyQueryTransactionScreen = lazy(() =>
   import(
      'screens/admin/companyQueryTransactionScreen/CompanyQueryTransactionScreen'
   )
);

const AdminRouter = () => {
   const { url } = useRouteMatch();
   return (
      <DisplayScreenForUserGroups
         authorizedGroups={[USER_GROUPS.ADMIN, USER_GROUPS.FCC]}
      >
         <Suspense fallback={<LoadingScreen />}>
            <Switch>
               <Route
                  path={`${url}/work-items`}
                  component={AdminViewWorkItemListScreen}
               />
               <Route
                  path={`${url}/work-item/:id`}
                  component={WorkItemScreen}
               />
               <Route path={`${url}/users`} component={AdminQueryUsers} />
               <Route path={`${url}/user/:email`} component={UserScreen} />
               <Route path={`${url}/companies`} component={CompaniesScreen} />
               <Route path={`${url}/billing`} component={BillingScreen} />
               <Route
                  path={`${url}/notifications`}
                  component={NotificationsScreen}
               />
               <Route
                  path={`${url}/company/:companyId`}
                  component={CompanyScreen}
               />
               <Route
                  path={`${url}/add-client/:companyId`}
                  component={AddClientCompanyScreen}
               />
               <Route
                  path={`${url}/userHistory/:email`}
                  component={UserHistoryScreen}
               />
               <Route
                  path={`${url}/companyHistory/:companyId`}
                  component={CompanyHistoryScreen}
               />
               <Route
                  path={`${url}/upload-reports`}
                  component={UploadReportsScreen}
               />
               <Route path={`${url}/manage-tns`} component={ManageTnsRouter} />
               <Route
                  path={`${url}/company-query-transactions`}
                  component={CompanyQueryTransactionScreen}
               />
               <Route
                  path={`${url}/downloads`}
                  render={() => (
                     <ResultFilesScreen
                        types={[
                           'Submitter TN Metrics',
                           'Caller Metrics',
                           'Admin Query TN Data',
                        ]}
                        queryType="system"
                        excludedColumns={['input_file', 'reportName']}
                        title="Downloads"
                        subtitle="Download files to create FCC reports and analysis. These files will not be deleted."
                     />
                  )}
               />
               <Route component={NotFoundScreen} />
            </Switch>
         </Suspense>
      </DisplayScreenForUserGroups>
   );
};

export { AdminRouter };
