import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    .form-group.required label:after { 
        content:" *";
        color:#dc3545;
    }
`;

// eslint-disable-next-line import/no-default-export
export default GlobalStyle;
