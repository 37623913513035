import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '@somosgov/react-component-lib';

import { USER_GROUPS } from 'util/constants';
import { DisplayScreenForUserGroups } from 'components/auth/DisplayScreenForUserGroups';

const NotFoundScreen = lazy(() => import('screens/NotFoundScreen'));
const TnDisconnectUploadScreen = lazy(() =>
   import('screens/submit/tnDisconnectUploadScreen/TnDisconnectUploadScreen')
);
const TnSubmissionReportScreen = lazy(() =>
   import('screens/submit/tnSubmissionReportScreen/TnSubmissionReportScreen')
);
const QueryTnDataScreen = lazy(() =>
   import('screens/submit/queryTnDataScreen/QueryTnDataScreen')
);
const ResultFilesScreen = lazy(() =>
   import('components/downloadsScreen/DownloadsScreen')
);

const submitReports = ['TN Submission Report Details', 'TN Data Report'];

const SubmitRouter = () => {
   const { url } = useRouteMatch();
   return (
      <DisplayScreenForUserGroups
         authorizedGroups={[
            USER_GROUPS.ADMIN,
            USER_GROUPS.SPTFNA,
            USER_GROUPS.FCC,
         ]}
      >
         <Suspense fallback={<LoadingScreen />}>
            <Switch>
               <Route
                  path={`${url}/tn-disconnect-upload`}
                  component={TnDisconnectUploadScreen}
               />
               <Route
                  path={`${url}/query-tn-data`}
                  component={QueryTnDataScreen}
               />
               <Route
                  path={`${url}/tn-submission-report`}
                  component={TnSubmissionReportScreen}
               />
               <Route
                  path={`${url}/result-files`}
                  render={() => (
                     <ResultFilesScreen
                        types={submitReports}
                        excludedColumns={['input_file', 'reportName']}
                        title="Result Files"
                        subtitle="Download the result files for your records. The result files will only be available for 45 days from the Create Date. Please note all date times are in Eastern time."
                     />
                  )}
               />
               <Route component={NotFoundScreen} />
            </Switch>
         </Suspense>
      </DisplayScreenForUserGroups>
   );
};

export { SubmitRouter };
