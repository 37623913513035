import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
   CONTACT_URL,
   SUBMIT_SCHEDULE_URL,
   TRAINING_VIDEOS_URL,
   USER_GROUPS,
   USER_GUIDES_URL,
} from 'util/constants';
import { AuthContext } from 'appRoot/auth/AuthContext';
import { useSubscription } from 'hooks/useSubscription';
import { RenderForUserGroups } from 'components/auth/RenderForUserGroups';
import {
   SiteHeaderLeftWrapper,
   SiteHeaderRightWrapper,
   SiteHeaderLogoLink,
   TopNav,
   TopNavCollapsibleMenu,
   TopNavMenu,
   TopNavMenuItem,
   TopNavUserLink,
   TopNavMenuLink,
   TopNavSubmenu,
   TopNavLogo,
   SiteHeader,
   TopNavHamburgerButton,
   TopNavHamburgerButtonWrapper,
} from '@somosgov/react-component-lib';
import { debounceTime, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import Logo from './siteHeader/RND_Logo_Small_White.png';

const ManageTnsMenu = () => (
   <TopNavMenuItem>
      <TopNavSubmenu title="Manage TNs">
         <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
            <TopNavMenuItem>
               <TopNavMenuLink
                  as={Link}
                  to="/admin/manage-tns/sp-upload-status"
               >
                  SP Upload Status
               </TopNavMenuLink>
            </TopNavMenuItem>
         </RenderForUserGroups>
         <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/admin/manage-tns/tn-exceptions">
                  TN Exceptions
               </TopNavMenuLink>
            </TopNavMenuItem>
         </RenderForUserGroups>
         <TopNavMenuItem>
            <TopNavMenuLink as={Link} to="/admin/manage-tns/tn-history">
               TN History
            </TopNavMenuLink>
         </TopNavMenuItem>
         <TopNavMenuItem>
            <TopNavMenuLink as={Link} to="/admin/manage-tns/tn-query-history">
               TN Query History
            </TopNavMenuLink>
         </TopNavMenuItem>
         <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/admin/manage-tns/update-tns">
                  Update TNs
               </TopNavMenuLink>
            </TopNavMenuItem>
         </RenderForUserGroups>
      </TopNavSubmenu>
   </TopNavMenuItem>
);

const AdminMenu = () => (
   <RenderForUserGroups authorizedGroups={[USER_GROUPS.ADMIN, USER_GROUPS.FCC]}>
      <TopNavMenuItem>
         <TopNavSubmenu title="Admin">
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/admin/work-items">
                     Work Items
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/admin/users">
                  Users
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/admin/companies">
                  Companies
               </TopNavMenuLink>
            </TopNavMenuItem>
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/admin/notifications">
                     Notifications
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <ManageTnsMenu />
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/admin/upload-reports">
                     Upload Reports
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/admin/billing">
                     Billing
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/admin/downloads">
                     Downloads
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink
                     as={Link}
                     to="/admin/company-query-transactions"
                  >
                     Company Query Transactions
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
         </TopNavSubmenu>
      </TopNavMenuItem>
   </RenderForUserGroups>
);

const SubmitMenu = () => (
   <RenderForUserGroups
      authorizedGroups={[
         USER_GROUPS.ADMIN,
         USER_GROUPS.SPTFNA,
         USER_GROUPS.FCC,
      ]}
   >
      <TopNavMenuItem>
         <TopNavSubmenu title="Submit">
            <RenderForUserGroups unauthorizedGroups={[USER_GROUPS.FCC]}>
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/submit/tn-disconnect-upload">
                     TN Disconnect Upload
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/submit/query-tn-data">
                  TN Data Report
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/submit/tn-submission-report">
                  TN Submission Report
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/submit/result-files">
                  Result Files
               </TopNavMenuLink>
            </TopNavMenuItem>
         </TopNavSubmenu>
      </TopNavMenuItem>
   </RenderForUserGroups>
);

const QueryMenu = () => (
   <RenderForUserGroups
      authorizedGroups={[
         USER_GROUPS.ADMIN,
         USER_GROUPS.CALLER,
         USER_GROUPS.FCC,
      ]}
   >
      <TopNavMenuItem>
         <TopNavSubmenu title="Query">
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/query/tn-query-upload">
                  TN Query Upload
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/query/tn-disconnect-query">
                  TN Query
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/query/query-summary-report">
                  Query Summary Report
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/query/query-results">
                  Downloads
               </TopNavMenuLink>
            </TopNavMenuItem>
         </TopNavSubmenu>
      </TopNavMenuItem>
   </RenderForUserGroups>
);

const ReportsMenu = () => (
   <TopNavMenuItem>
      <TopNavMenuLink as={Link} to="/reports">
         Reports
      </TopNavMenuLink>
   </TopNavMenuItem>
);

const SupportMenu = () => (
   <TopNavMenuItem>
      <TopNavSubmenu title="Support">
         <TopNavMenuItem>
            <TopNavMenuLink href={CONTACT_URL} target="_blank">
               Contact Help Desk
            </TopNavMenuLink>
         </TopNavMenuItem>
         <TopNavMenuItem>
            <TopNavMenuLink href={SUBMIT_SCHEDULE_URL} target="_blank">
               Submit Schedule
            </TopNavMenuLink>
         </TopNavMenuItem>
         <TopNavMenuItem>
            <TopNavMenuLink href={USER_GUIDES_URL} target="_blank">
               User Guides
            </TopNavMenuLink>
         </TopNavMenuItem>
         <TopNavMenuItem>
            <TopNavMenuLink href={TRAINING_VIDEOS_URL} target="_blank">
               Training Videos
            </TopNavMenuLink>
         </TopNavMenuItem>
      </TopNavSubmenu>
   </TopNavMenuItem>
);

const UserMenu = ({ username }) => {
   const { subscription } = useSubscription();

   const history = useHistory();

   return (
      <TopNavMenuItem>
         <TopNavSubmenu
            horizontalAlign="right"
            component={({ onClick, hasSubmenu, submenuOpen }) => (
               <TopNavUserLink
                  username={username}
                  hasSubmenu={hasSubmenu}
                  subMenuOpen={submenuOpen}
                  onClick={onClick}
                  as="button"
               />
            )}
         >
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/account/profile">
                  Profile
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/account/company">
                  Company
               </TopNavMenuLink>
            </TopNavMenuItem>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/account/users">
                  Users
               </TopNavMenuLink>
            </TopNavMenuItem>
            <RenderForUserGroups
               authorizedGroups={[
                  USER_GROUPS.CALLER,
                  USER_GROUPS.COMPANY_ADMIN,
               ]}
               requireAllAuthorized
            >
               <TopNavMenuItem>
                  <TopNavMenuLink
                     as={Link}
                     to={`/account/subscription/${
                        subscription ? 'manage' : 'create'
                     }`}
                  >
                     Subscription
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <TopNavMenuItem>
               <TopNavMenuLink as={Link} to="/account/notifications">
                  Notifications
               </TopNavMenuLink>
            </TopNavMenuItem>
            <RenderForUserGroups
               authorizedGroups={[
                  USER_GROUPS.CALLER,
                  USER_GROUPS.COMPANY_ADMIN,
               ]}
               requireAllAuthorized
            >
               <TopNavMenuItem>
                  <TopNavMenuLink as={Link} to="/account/api-credentials">
                     API Credentials
                  </TopNavMenuLink>
               </TopNavMenuItem>
            </RenderForUserGroups>
            <RenderForUserGroups
               authorizedGroups={[USER_GROUPS.CALLER, USER_GROUPS.SPTFNA]}
            >
               <RenderForUserGroups
                  authorizedGroups={[USER_GROUPS.COMPANY_ADMIN]}
               >
                  <TopNavMenuItem>
                     <TopNavMenuLink as={Link} to="/account/sftp-credentials">
                        SFTP Credentials
                     </TopNavMenuLink>
                  </TopNavMenuItem>
               </RenderForUserGroups>
            </RenderForUserGroups>
            <TopNavMenuItem>
               <TopNavMenuLink
                  as="button"
                  type="button"
                  onClick={() =>
                     Auth.signOut({ global: true })
                        .catch((e) => {
                           if (e.code === 'NotAuthorizedException')
                              window.location.href = '/';
                        })
                        .then(() => {
                           localStorage.clear();
                           history.push('/');
                        })
                  }
               >
                  Logout
               </TopNavMenuLink>
            </TopNavMenuItem>
         </TopNavSubmenu>
      </TopNavMenuItem>
   );
};

UserMenu.propTypes = {
   username: PropTypes.string,
};

UserMenu.defaultProps = {
   username: '',
};

const CollapsibleMenu = ({ authState }) => (
   <TopNavCollapsibleMenu>
      {authState === 'loggedIn' && (
         <>
            <AdminMenu />
            <SubmitMenu />
            <QueryMenu />
            <ReportsMenu />
            <SupportMenu />
         </>
      )}
   </TopNavCollapsibleMenu>
);

CollapsibleMenu.propTypes = {
   authState: PropTypes.string.isRequired,
};

const SkipLink = styled(TopNavMenuLink)`
   position: absolute;
   left: 100%;
   color: white;
   white-space: nowrap;
   overflow: hidden;
   opacity: 0;
   width: 0;
   padding: 0;

   &:focus {
      border-bottom-color: #f5a623;
      color: white;
      opacity: 1;
      width: auto;
      padding: 0 1em;
   }
`;

const LeftWrapper = styled(SiteHeaderLeftWrapper)`
   position: relative;
`;

const Header = () => {
   const expandMenuAt = '1240px';
   const getMenuExpanded = () =>
      window.matchMedia(`(min-width: ${expandMenuAt})`).matches;
   const [menuExpanded, setMenuExpanded] = useState(getMenuExpanded());
   const { username, authState } = useContext(AuthContext);

   useEffect(() => {
      fromEvent(window, 'resize')
         .pipe(
            debounceTime(250),
            tap(() => setMenuExpanded(getMenuExpanded()))
         )
         .subscribe();
   }, []);

   return (
      <SiteHeader fixed expandMenuAt={expandMenuAt}>
         <LeftWrapper>
            <SiteHeaderLogoLink as={Link} to="/">
               <TopNavLogo src={Logo} alt="Home Page Header" />
            </SiteHeaderLogoLink>
            {authState === 'loggedIn' && (
               <SkipLink href="#page-heading">Skip to content</SkipLink>
            )}
         </LeftWrapper>

         <SiteHeaderRightWrapper as="nav" aria-label="Main">
            {menuExpanded && <CollapsibleMenu authState={authState} />}
            <TopNav as="div">
               {authState === 'loggedIn' && (
                  <TopNavMenu>
                     <UserMenu username={username} />
                  </TopNavMenu>
               )}
            </TopNav>
            {authState === 'loggedIn' && (
               <TopNavHamburgerButtonWrapper>
                  <TopNavHamburgerButton />
               </TopNavHamburgerButtonWrapper>
            )}
            {!menuExpanded && <CollapsibleMenu authState={authState} />}
         </SiteHeaderRightWrapper>
      </SiteHeader>
   );
};

export { Header as SiteHeader };
